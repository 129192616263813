<template>
    <div class="drawElectronicFence">
        <div class="map-con">
            <!-- 操作按钮 -->
            <div class="handle-types">
                <el-button
                    type="primary"
                    round
                    plain
                    size="small"
                    @click="handleAddMark"
                    :class="{'active': handleType===1}"
                >
                    位置标点
                </el-button>
                <el-button
                    type="primary"
                    round
                    plain
                    size="small"
                    @click="handleDrawFence"
                    :class="{'active': handleType===2}"
                    v-if="!onlyPoint"
                >
                    绘制电子围栏
                </el-button>
            </div>
            <!-- 搜索 -->
            <!-- <el-amap-search-box class="search-box" :search-option="searchOption" :on-search-result="onSearchResult"></el-amap-search-box> -->
            <div class="search-con">
                <input
                    type="text"
                    autocomplete="off"
                    id="mymap_search"
                    name="mymap_search"
                    v-model="searchVal"
                >
                <span class="search-btn" @click="search">搜索</span>
            </div>
            <!-- 地图 -->
            <el-amap
                class="amap-box"
                :vid="'amap-vue'"
                :zoom="zoom"
                :center="mapCenter"
                :amap-manager="amapManager"
            >
                <!-- 点标记 -->
                <el-amap-marker
                    v-for="(marker, index) in markers"
                    :key="`marker${index}`"
                    :position="marker.position"
                    :icon="marker.icon"
                    :size="marker.size"
                    :events="marker.events"
                ></el-amap-marker>
                <!-- 已经关联项目点标记 -->
                <el-amap-marker
                    v-for="(item, i) in associatedProject"
                    :position="item.position"
                    :icon="item.icon"
                    :key="`associatedProject${i}`"
                    :label="item.label"
                    :events="item.events"
                    :vid="i"
                ></el-amap-marker>
                <!-- 未关联项目点标记 -->
                <el-amap-marker
                    v-for="(item, i) in unconnectedItems"
                    :position="item.position"
                    :icon="item.icon"
                    :key="`unconnectedItems${i}`"
                    :label="item.label"
                    :events="item.events"
                    :vid="i"
                ></el-amap-marker>
                <!-- 信息窗体 -->
                <el-amap-info-window
                    :position="window.position"
                    :visible="window.visible"
                    :content="window.content"
                    :offset="[0, -30]"
                ></el-amap-info-window>
            </el-amap>
            <div class="handle-btn">
                <el-button type="primary" size="medium" @click="handleSave">
                    确定
                </el-button>
                <el-button size="medium" @click="handleClose">
                    取消
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import VueAMap from 'vue-amap';
import util from '@/utils/util';
VueAMap.initAMapApiLoader({
    key: 'b4007ecc143884e79eab2c515ede82cf',
    // eslint-disable-next-line max-len
    plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Geolocation', 'AMap.Geocoder', 'AMap.AMapManager', 'AMap.Marker', 'AMap.GeometryUtil','AMap.MouseTool'],
    v: '1.4.4',
});
const amapManager = new VueAMap.AMapManager();
export default {
    components: {},
    props: {
        extr: {
            type: Object,
        },
    },
    data() {
        return {
            amapManager,
            zoom: 14,
            mapCenter: [116.397428, 39.90923],
            mouseTool: {},
            markers: [],
            overlays:[],
            window: {
                position: [0.0, 0.0],
                visible: false,
                offset: [40, 0],
            },
            mapObj: {}, // 地图对象
            handleType: 1, // 1 打点 2 绘制围栏
            echoPolygon: null, // 回显围栏图层
            address: '', // 位置地址
            onlyPoint: false,
            placeSearch: {},
            searchVal: '',
            // 已关联项目点标记
            associatedProject:[],
            // 未关联项目点标记
            unconnectedItems:[],
            // 已选关联项目
            selectAssociatedProjects:''
        };
    },
    watch: {},
    computed: {},
    methods: {
        // 确定
        handleSave() {
            if(this.selectAssociatedProjects === ''){
                this.selectAssociatedProjects = this.extr.projectnumber;
            }
            if (this.markers[0].position&&this.overlays.length>0) {
                const arr = [];
                this.overlays.map(item=>{
                    arr.push([item.lng,item.lat]);
                });
                const b = AMap.GeometryUtil.isPointInRing(this.markers[0].position, arr);
                if (b) {
                    this.extr.sureCallback(this.overlays,this.markers[0].position,this.address,this.selectAssociatedProjects);
                    window.hideToast();
                } else {
                    this.$message.warning('位置标点不在电子围栏范围内，请重新标注或重新绘制围栏！');
                }
            } else {
                if (this.markers[0].position) {
                    this.extr.sureCallback(this.overlays,this.markers[0].position,this.address,this.selectAssociatedProjects);
                    window.hideToast();
                } else {
                    this.$message.warning('请绘制坐标点！');
                }
            }

        },
        // 取消
        handleClose() {
            window.hideToast();
        },

        // 绘制
        draw() {
            const polygon = new AMap.Polygon({
              fillColor:'#00b0ff',
              strokeColor:'#80d8ff',
              path: [],
              //同Polygon的Option设置
            });
            return polygon;
        },
        // 搜索打点
        search() {
            this.placeSearch.search(this.searchVal,  (status, result)=> {
                if (result.poiList && result.poiList.pois && result.poiList.pois.length > 0) {
                    let detailedAddress = result.poiList.pois[0]
                    if (detailedAddress) {
                        this.placeSearch.setCity(detailedAddress.cityname);
                        this.searchVal = detailedAddress.name;
                        let lat = detailedAddress.entr_location.lat;
                        let lng = detailedAddress.entr_location.lng;
                        this.markers = [];
                        const markerObj = {
                            position: [0.0, 0.0],
                            icon: './../images/mapIcon1.png',
                            events: {
                                click: () => {
                                    this.switchWindow();
                                },
                            },
                        }
                        markerObj.position = [lng, lat];
                        this.window.visible = false;
                        this.window.position = [lng, lat];
                        this.window.content = detailedAddress.name;
                        this.address = detailedAddress.name;

                        // 获取标记周边项目
                        this.getUnconnectedItems(markerObj.position)

                        setTimeout(() => {
                            this.window.visible = true;
                            this.markers.push(markerObj);
                            this.mapCenter = [lng, lat];
                            this.$forceUpdate()
                        }, 500);
                    }

                }
            });
        },
        // 绘制电子围栏
        drawFence() {
            this.mouseTool.polygon({
                fillColor:'#00b0ff',
                strokeColor:'#80d8ff',
                path: [],
                //同Polygon的Option设置
            });
            //监听draw事件可获取画好的覆盖物
            var overlays = [];
            // const polygon = this.draw();
            this.mouseTool.on('draw',(e)=>{
                this.overlays = e.obj.w.path;
                this.mouseTool.close(false);
            });
        },
        handleClick(that,e) {
            if (that.handleType === 1) {
                const addressX = e.lnglat.lng;
                const addressY = e.lnglat.lat;
                that.$nextTick(()=>{
                    that.markers = [];
                    const markerObj = {
                        position: [0.0, 0.0],
                        icon: require('./../images/mapIcon1.png'),
                        events: {
                            click: () => {
                                that.switchWindow();
                            },
                        },
                    }
                    markerObj.position = [addressX,addressY];
                    that.markers.push(markerObj);
                });
                let geocoder = '';
                that.mapObj.plugin(["AMap.Geocoder"],()=>{
                    geocoder = new AMap.Geocoder({
                        city: "全国" //城市，默认：“全国”
                    });
                });
                const lnglatXY = [addressX,addressY];

                // 获取标记周边项目
                that.getUnconnectedItems(lnglatXY)

                geocoder.getAddress(lnglatXY, function (status, result) {
                    if (status === 'complete' && result.info === 'OK') {
                        const address = result.regeocode.formattedAddress;
                        if (address) {
                            that.window.visible = false;
                            that.window.position = lnglatXY;
                            that.window.content = address;
                            that.address = address;
                            that.$nextTick(() => {
                                that.window.visible = true;
                                that.mapCenter = lnglatXY;
                            });
                        }
                    }
                });
            }
        },
        // 打点
        addMark() {
            const that = this;
            if (this.echoPolygon) {
                this.echoPolygon.on('click', function(e) {
                    that.handleClick(that,e);
                });
            }
            this.mapObj.on('click', function (e) {
                that.handleClick(that,e);
            });
        },
        // 此时操作打点
        handleAddMark() {
            this.handleType = 1;
            this.mouseTool.close(false);
            this.addMark();
        },
        // 此时操作绘制围栏
        handleDrawFence() {
            this.handleType = 2;
            this.window.visible = false;
            this.mouseTool.close(true);
            this.overlays = [];
            if(this.echoPolygon){
                this.mapObj.remove(this.echoPolygon);
            }
            this.drawFence();
        },
        // 回显电子围栏
        echoPolygonPath(pointstr) {
            const arr = pointstr.split('|');
            const polygonPath = [];
            const overlays = [];
            arr.map(item=>{
                const lngLat = item.split(',');
                const obj = new AMap.LngLat(lngLat[0], lngLat[1]);
                polygonPath.push(obj);
                overlays.push({lng:lngLat[0],lat:lngLat[1]});
            });
            this.overlays = overlays;
            // 创建面覆盖物
            this.echoPolygon = new AMap.Polygon({
                fillColor:'#00b0ff',
                strokeColor:'#80d8ff',
                path: polygonPath,
                fillOpacity: 0.3,
                strokeWeight: 2,
            });
            this.mapObj.add([this.echoPolygon]);
        },
        // 显示信息窗体
        switchWindow() {
            this.window.visible = false;
            this.window.position = this.markers[0].position;
            this.window.content = this.address;
            this.address = this.address;
            this.$nextTick(() => {
                this.window.visible = true;
            });
        },

        // 获取标记周边已关联项目
        getAssociatedProject(){
            this.$axios
                .get(`/interfaceApi/sale/engineering_manager/get_coordinate_by_num/${this.extr.projectnumber}`)
                .then(res => {
                    this.associatedProject = [];
                    if (res) {
                        this.$nextTick(()=>{
                            const markerObj = {
                                position: [res.x_coordinate_point, res.y_coordinate_point],
                                icon: require('./../images/mapIcon3.png'),
                                label: {
                                    content: res.project_name,
                                },
                                events: {
                                    click: () => {
                                        // _that.associatedProjects(item);
                                    },
                                },
                            }
                            this.associatedProject.push(markerObj);
                        })
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },

        // 获取标记周边项目
        getUnconnectedItems(coordinate){
            if(this.extr.type && this.extr.type === 1){
                const _that = this;
                var newCoordinate = '';
                if(!coordinate || coordinate.length < 2){
                    return;
                } else {
                    coordinate.forEach((item, index) => {
                        if (index < coordinate.length-1) {
                            newCoordinate += item + ',';
                        } else {
                            newCoordinate += item;
                        }
                    })
                }
                this.$axios
                    .post('/interfaceApi/sale/engineering_manager/get_project_coordinate',{
                        coordinate: newCoordinate,
                        distance: 10
                    })
                    .then(res => {
                        _that.unconnectedItems = [];
                        if (res && res.length > 0) {
                            _that.$nextTick(()=>{
                                res.forEach(item => {
                                    const markerObj = {
                                        position: [item.x_coordinate_point, item.y_coordinate_point],
                                        icon: require('./../images/mapIcon2.png'),
                                        label: {
                                            content: item.project_name,
                                        },
                                        events: {
                                            click: () => {
                                                _that.associatedProjects(item);
                                            },
                                        },
                                    }
                                    _that.unconnectedItems.push(markerObj);
                                })
                            })
                        }
                    })
                    .catch(error => {
                        _that.$message.error(error.ErrorCode.Message);
                    });
            }
        },
        // 关联项目
        associatedProjects(data){
            this.$confirm(`确认关联【${data.project_name}】项目？`, '确认关联', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.selectAssociatedProjects = data.project_number;
                this.$message({
                    type: 'success',
                    message: '添加关联!'
                });
            });
        },
        // 获取默认地址
        getDefaultPostion(){
            this.$axios.get('/interfaceApi/BaseInfo/tenantposition/info')
            .then(pos=>{
                this.extr.position = [pos.longitude, pos.latitude];
            });
        },
    },
    created() {
        //如果未选择地址 地址为空，使用默认地址
        if(!(this.extr.position[0]&&this.extr.position[1])){
            this.getDefaultPostion();
        }
    },
    mounted() {

        if (this.extr.onlyPoint) {
            this.onlyPoint = this.extr.onlyPoint;
        }
        if (this.extr.address) {
            this.address = this.extr.address;
        }
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.src = 'https://cache.amap.com/lbs/static/addToolbar.js';
        document.body.appendChild(s);
        // 初始化绘制多边形组件
        setTimeout(()=>{
            this.$nextTick(()=>{
                this.mapObj = amapManager.getMap();
                this.mapObj.plugin(["AMap.MouseTool"],()=>{
                    this.mouseTool = new AMap.MouseTool(this.mapObj);
                });
                this.mapObj.plugin(['AMap.Autocomplete','AMap.PlaceSearch'],()=>{
                    var autoOptions = {
                        city: "全国", //城市，默认全国
                        input: "mymap_search"//使用联想输入的input的id（也就是上边那个唯一的id）
                    };
                    const autocomplete= new AMap.Autocomplete(autoOptions);
                    this.placeSearch = new AMap.PlaceSearch({
                        pageSize: 1,
                        pageIndex: 1,
                        city: "全国", //城市
                        map:this.mapObj
                    })
                    AMap.event.addListener(autocomplete, "select", (e)=>{
                        //TODO 针对选中的poi实现自己的功能
                        // placeSearch.setCity(e.poi.adcode);
                        // placeSearch.search(e.poi.name)
                        let latSum = 0;
                        let lngSum = 0;
                        this.markers = [];
                        const markerObj = {
                            position: [0.0, 0.0],
                            icon: require('./../images/mapIcon1.png'),
                            events: {
                                click: () => {
                                    this.switchWindow();
                                },
                            },
                        }
                        markerObj.position = [e.poi.location.lng, e.poi.location.lat];
                        this.window.visible = false;
                        this.window.position = [e.poi.location.lng, e.poi.location.lat];
                        this.window.content = e.poi.name;
                        this.address = e.poi.name;
                        this.searchVal = e.poi.name;
                        setTimeout(() => {
                            this.window.visible = true;
                            this.markers.push(markerObj);
                            this.mapCenter = [e.poi.location.lng, e.poi.location.lat];
                            this.$forceUpdate()
                        }, 500);
                    });
                });
                // 回显电子围栏
                if (this.extr.fence_point) {
                    this.echoPolygonPath(this.extr.fence_point);
                }
                if (this.extr.position[0]&&this.extr.position[1]) {
                    // 回显标点
                    this.markers = [];
                    const markerObj = {
                        position: [0.0, 0.0],
                        icon: require('./../images/mapIcon1.png'),
                        events: {
                            click: () => {
                                this.switchWindow();
                            },
                        },
                    }
                    markerObj.position = this.extr.position;
                    this.markers.push(markerObj);
                    this.mapCenter = this.extr.position;
                }
                if(this.extr.projectnumber && this.extr.projectnumber !== '' && this.extr.type === 1){
                     // 获取标记周边已关联项目
                    this.getAssociatedProject();
                }
                // 默认操作打点
                this.addMark();
            });
        },500);
    },
};
</script>
<style lang="stylus">
.amap-sug-result
    z-index 10000
.drawElectronicFence
    height 100%
    padding .1rem
    overflow hidden
    .map-con
        height 100%
        position relative
        .search-con
            position absolute
            top .1rem
            left .1rem
            height .45rem
            display flex
            z-index: 10;
            box-shadow: 0 2px 2px rgba(0,0,0,.15);
            input
                width 3.15rem
                background #ffffff
                border none
                padding 0 .1rem
            .search-btn
                width .45rem
                background #1577d2
                color #fff
                border-radius 0 3px 3px 0
                text-align center
                line-height .45rem
                cursor pointer
        .amap-box
            height calc(100% - .7rem)
            .amap-marker
                position relative
                .amap-marker-label
                    top -26px !important
                    left 10px !important
                    transform translateX(-50%) !important
                    background #fff
                    padding 5px
                    line-height 1.4
                    box-shadow 0 1px 2px rgba(0,0,0,0.1)
                    border-radius 3px
                    border none
        .handle-btn
            padding-top .2rem
            text-align center
        .handle-types
            position absolute
            bottom .1rem
            right .1rem
            background #fff
            padding .1rem
            .el-button
                height 0.36rem
                padding 0 0.2rem
            .active
                background #409EFF
                border-color #409EFF
                color #fff
</style>
